.btn {
    border: 1px solid #333;
}

.btn-primary {
    background-color: #0080ff;
    border: 1px solid #0080ff;
}

.btn-secondary {
    background-color: #ea4e6d;
    border: 1px solid #ea4e6d;
}

.btn-full {
    width: 100%;
}

.btn-primary-outline {
    color: #0080ff;
    border: 1px solid #0080ff;

    &:hover {
        border: 1px solid #0080ff;
    }
}

.btn-none {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

.btn-rounded {
    border-radius: 24px;
}

.show-selected {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin-right: 8px;
    background-color: #ea4e6d;
}
