.auth-wrapper {
    @media (min-width: 768px) {
        height: 100vh;
    }

    .full-height {
        background-color: #00A9DC;
    }
}

.full-height {
    @media (min-width: 768px) {
        height: 100%;
    }
}

.auth-layout-banner {
    .auth-logo {
        width: 50%;
        border: 1px solid #fff;
    }
    p {
        font-size: 2rem;
        font-weight: 500;
        color: #fff;
    }
}

a, p {
    text-decoration: none;
    color: #333;
}

.nav-logo {
    height: 2rem;
}

.activeClassName li {
    border-left: 5px solid #2483c0;
    background-color: #2483c030;

    span {
        color: #2483c0;
        font-weight: 500;
    }
    img {
        filter: invert(44%) sepia(58%) saturate(1487%) hue-rotate(178deg) brightness(82%) contrast(78%);
    }

}