.text-blue {
    background-color: #caf4ff;
}
.text-yellow {
    background-color: #ffca00;
}
.text-orange {
    background-color: #ff9551;
}
.text-red {
    background-color: #ff3030;
}