.form-group {
    label, input {
        display: block;
    }

    input {
        margin-top: 8px;
        padding: 0.6rem 1rem;
        border: 1px solid #333;
        border-radius: 4px;
        width: 100%;
    }
    .error-field {
        border: 1px solid #ff2200;
    }

    .error {
        color: #ff2200;
        font-size: 14px;
        font-weight: 500;
    }
}