@import "~bootstrap/scss/bootstrap";
@import './assets/styles/styles.scss';

* {
    box-sizing: border-box;
}

html, body {
    font-size: 14px;
}

Navbar {
    height: 100%;
    min-height: 90vh;
}

nav {
    min-height: 100vh;
}

.nav-item {
    width: 100%;
    padding: 1rem 2rem;
    text-decoration: none;

    &:hover {
        background-color: #09afe315;
        border-radius: 4px;
    }

    img {
        padding-right: 1em;
        filter: invert(39%) sepia(6%) saturate(185%) hue-rotate(202deg) brightness(94%) contrast(91%);
    }

    span {
        color: #616164;
    }
}

.active-nav {
    border: 2px solid #09afe3;
    border-radius: 4px;
    font-weight: 500;

    img {
        filter: invert(59%) sepia(42%) saturate(5462%) hue-rotate(160deg) brightness(101%) contrast(93%);
    }
    span {
        color: #09afe3;
    }
}


.bg-grey {
    background-color: #f7f7f7;
}

TableHead {
    TableRow {
        TableCell {
            font-weight: 500 !important;
        }
    }
}

th {
    font-weight: 800 !important;
}

.error-message {
    padding: 0.5rem 2rem;
    border-radius: 4px;
    border: 1px solid #ff0000;
    color: #ff0000;
    background-color: #ff000015;
}

.page-not-found {
    img {
        margin-top: 5rem;
        margin-bottom: 5rem;
        height: 40vh;
    }
}

.hint {
    font-size: 0.9rem;
    margin-left: 0.3rem;
    color: #1f1f1f;
}

.summary-wrapper {
    background-color: #09afe3;
    border-radius: 6px;
    width: fit-content;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(9,175,227,0.55);
    -moz-box-shadow: 0px 4px 5px 0px rgba(9,175,227,0.55);
    box-shadow: 0px 4px 5px 0px rgba(9,175,227,0.55);

    .summary {
        width: 100% !important;
        border: none;
        border-bottom: 2px solid #fff;

        @media only screen and (min-width:70em) {
            width: fit-content !important;
            border: none;
            border-right: 2px solid #fff;
        }

        &:nth-last-child(1) {
            border: none;
        }

        p {
            margin: 0;
            color: #fff;
        }
        .title {
            font-size: 1.1rem;
            font-weight: 500;
        }
        .value {
            font-size: 1.5rem;
            font-weight: 700;
        }
        .value-large {
            font-size: 2rem;
            font-weight: 700;
        }
    }
}

.user-wrapper {
    background-color: #09afe3 !important;
    
    p {
        margin: 0;
        color: #fff;
        font-weight: 600;
        font-size: 1.2rem
    }
}

.primary-color {
    color: #09afe3 !important;
}

.product-wrapper {
    border-radius: 6px;
    border: 2px solid #09afe3;
}