.card {
    width: 100%;
    padding: 1rem 2rem;
    margin-right: 1rem;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.45);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.45);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.45);

    @media (min-width: 576px) {  }

    @media (min-width: 768px) { 
        width: 47%;
    }

    @media (min-width: 992px) { 
        width: 23%;
    }
    
    .card-title, .card-value {
        margin: 0;
    }

    .card-title {
        color: #65737e
    }
    
    .card-value {
        font-size: 1.5rem;
        font-weight: 500;
    }
}

.dues-card {
    border: none;
    border-left: 8px solid #848484;

    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 4px 0px rgb(203, 225, 246);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0,128,255,1);
        box-shadow: 0px 0px 4px 0px rgba(0,128,255,1);
    }

    &.dues-selected {
        border: 3px solid #e8f4ff30;
    }

    &.green { 
        border-color: #1eff5d;
    }
    &.blue { 
        border-color: #caf4ff;
    }
    &.yellow { 
        border-color: #ffca00;
    }
    &.orange { 
        border-color: #ff9551;
    }

    &.red { 
        border-color: #ff3030bf;
    }
}

